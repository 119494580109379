import { useState } from "react";
import "./App.css";

const SCALES = [
  { name: "Fibonacci", values: [1, 2, 3, 5, 8, 13, 21] },
  { name: "Fibonacci-ish", values: [1, 1.1, 1.3, 1.6, 2.1, 2.9, 4.2, 6.3] },
  { name: "Fibonacci 50", values: [1, 2, 2.5, 3.5, 5, 7.5, 11.5] },
  { name: "Fibonacci 10", values: [1, 1.2, 1.3, 1.5, 1.8, 2.3, 3.1] },
  { name: "Linear 0.5", values: [1, 1.5, 2, 2.5, 3, 3.5, 4] },
];

function App() {
  const [scale, setScale] = useState(SCALES[0]);

  return (
    <div className="app">
      <Menu scales={SCALES} onSelect={setScale} />
      <Scale scale={scale} />
    </div>
  );
}

function Menu({ scales, onSelect }) {
  return (
    <nav className="menu">
      {scales.map((scale, i) => (
        <button key={i} onClick={() => onSelect(scale)}>
          {scale.name}
        </button>
      ))}
    </nav>
  );
}

function Scale({ scale }) {
  return (
    <div className="scale">
      {[...scale.values].reverse().map((size, i) => (
        <div key={i} className="item" style={{ "--size": size }}>
          Supreme {size}
        </div>
      ))}
    </div>
  );
}

export default App;
